
const BlogsData = [

    { 
        title: 'Pakistan climate change act — challenges', 
        excerpt: 'Hasnain Ibrahim Kazmi, June 30, 2024', 
        content: 'The Government of Pakistan ratified several international climate agreements, including the UNFCCC, the Kyoto Protocol, and the Paris Agreement, committing to extensive adaptation and mitigation measures. The UNFCCC emphasizes a collective global response to climate change, aiming to stabilize greenhouse gas levels. In 2012, the states reaffirmed their commitment at the COP18 in Doha, setting a timetable for a Universal Climate Agreement by 2015, which culminated in the Paris Agreement. To fulfill these obligations, Pakistan established the Climate Change Authority under the Pakistan Climate Change Act of 2017, overseen by the Pakistan Climate Change Council chaired by the Prime Minister. However, the Authority was not constituted until a Supreme Court intervention in 2022. The Act lacks a comprehensive framework for implementing climate obligations, including defining climate-related offenses, penal consequences, and a prosecution mechanism. Environmental and climate issues are often conflated in Pakistan, hindering effective climate action. The Act mandates various initiatives but lacks enforcement procedures, unlike the Pakistan Environmental Protection Act of 1997, which includes provisions for offenses and penalties. Without enforcement mechanisms, the Climate Change Act is ineffective. The Federal Government is urged to amend the Act to address these challenges and ensure its successful implementation.' ,
        reference: 'https://tribune.com.pk/story/2475219/1',
        
    },


    { 
        title: 'BRI projects to be revived: Iqbal', 
        excerpt: 'APP, May 29, 2024', 
        content: 'Federal Minister for Planning, Development, and Special Initiatives Ahsan Iqbal expressed optimism about reinvigorating projects under China Belt and Road Initiative (BRI) to boost Pakistan economy. Iqbal mentioned forming joint ventures in renewable energy, agricultural production, and attracting Chinese companies to relocate to Pakistan. He noted a renewed interest from China to advance the China-Pakistan Economic Corridor (CPEC) into its second phase. CPEC, a key component of BRI, includes significant projects like the Gwadar port and new power plants, though progress slowed due to the pandemic and Pakistans economic challenges. Despite these setbacks, projects worth $25 billion were completed in the first phase, including power plants resolving the electricity deficit. Recently, a long-delayed railway upgrade was approved, set to be completed in two phases to reduce financial strain on Pakistan. Iqbal highlighted completed projects such as a water-supply initiative in Gwadar, port dredging, and an electricity transmission line from Iran, boosting China confidence in Pakistan commitment to CPEC. As part of CPEC 10th anniversary, China announced five new growth corridors to stimulate Pakistan economy. The second phase will likely see increased private sector involvement and efforts to attract Chinese firms facing rising costs and geopolitical tensions in China.' ,
        reference: 'https://tribune.com.pk/story/2468759/bri-projects-to-be-revived-iqbal',
    },
    { 
        title: 'Study advocates battery integration for renewable energy', 
        excerpt: ' Salman Siddiqui May 09, 2024',  
        content: 'A recent study suggests integrating battery energy storage systems (BESS) with solar and wind projects to optimize energy use in Pakistan. As the government considers reducing solar power prices to Rs11 per unit for net metering, the study highlights the benefits of storing surplus energy during low demand hours for use during peak times, potentially reducing costs to 6-8 cents per unit.The study, conducted by Renewables First and the Policy Research Institute for Equitable Development, argues that combining BESS with renewable energy could significantly lower electricity prices and provide power to remote areas. Pakistan variable electricity demand, with peak demand rising and base demand stagnating, has led to an installed generation capacity nearly double the utilised capacity, burdening consumers. The research emphasizes the commercial viability of BESS, projected to grow to $120-150 billion by 2030, and its advantages in frequency regulation and peak charge avoidance. Integrating BESS with renewable energy promotes environmental sustainability by reducing reliance on fossil fuels and lowering consumer tariffs.The study calls for government action to support market players by removing barriers in the Competitive Trading Bilateral Contracts Market (CTBCM). It notes that many industrial and commercial consumers are investing in off-grid solutions, potentially diminishing national grid demand. Researcher Ubaid Ullah Khan highlights that integrated batteries ensure consistent and cost-effective power supply, saving the national exchequer millions.' ,
        reference: 'https://tribune.com.pk/story/2465770/study-advocates-battery-integration-for-renewable-energy',
     },
     { 
        title: 'Reliance on renewables a must        ', 
        excerpt: 'Tribune February 29, 2024', 
        content: 'At a webinar organized by the Sustainable Development Policy Institute (SDPI), experts emphasized that Pakistan’s renewable energy ambitions must address system constraints to manage unserved energy and the intermittency of renewables. The webinar, titled “Emerging trends in Pakistan’s renewable energy sector: Charting the agenda for 2030 and beyond,” highlighted the need for grid modernization alongside renewable energy expansion.Sadia Dada, K-Electric’s Chief Marketing and Communications Officer, outlined KE’s Investment Plan, which aims to expand transmission and distribution capacity to accommodate 1200 MW of renewable energy by FY2030. This plan aligns with national targets and seeks to balance cost, policy, and socio-economic factors. Dada emphasized reducing reliance on imported fuels to 51% and increasing green energy to 49% within seven years, while addressing climate change impacts on grid stability. She noted the unique energy demands of Karachi, which peaks twice daily, necessitating a tailored approach. Energy Consultant Dr. Irfan Ahmed highlighted the need for upfront investments in renewable projects and local manufacturing to overcome foreign exchange constraints. He stressed the importance of repairing non-operational plants locally and producing spare parts to sustain operations and prevent revenue loss due to Pakistan "bumpy" electrical network.' ,
        reference: 'https://tribune.com.pk/story/2457921/reliance-on-renewables-a-must',
     },
     { 
        title: 'KE plans ambitious 640MW renewable energy initiative', 
        excerpt: 'Zafar Bhutta November 07, 2023', 
        content: 'K-Electric (KE), Karachi primary electricity provider, is implementing a strategic renewable energy plan to add 640 MW of renewable capacity to the local grid. KE has submitted revised Requests for Proposals (RFPs) for four renewable projects, adhering to NEPRA competitive bidding regulations. These projects include the Winder and Bela Solar Projects (150 MW), Dhabeji Site Neutral Hybrid Plant (220 MW), and the Sindh Solar Energy Project (270 MW across two sites), aiming to diversify the energy mix, reduce costs, and lower emissions.Key projects include a 50 MW plant at Winder and a 100 MW plant at Bela, with plans for new transmission lines to improve power supply reliability in Balochistan. NEPRA will review these projects in a public hearing. The Dhabeji Hybrid Plant will combine wind and solar energy, while the Sindh Solar Projects are developed in collaboration with the Sindh Energy Department and the World Bank, with private sector entities through competitive bidding. KE Power Acquisition Programme targets 1,200 MW of renewable energy by 2030. To integrate variable renewable energy (VRE) into its network, KE is conducting a VRE Integration Study with international consultants, aiming to optimize renewable capacity while ensuring grid reliability. In the financial year 2023, KE faced significant challenges due to the economic crisis, recording a loss of nearly Rs31 billion. The crisis led to a 7.3% reduction in units sent out, a decline in gross profitability by Rs15.72 billion, increased exchange losses by Rs4.38 billion due to currency devaluation, and an increase in impairment losses by Rs6.28 billion due to high inflation and economic contraction.' ,
        reference: 'https://tribune.com.pk/story/2445161/ke-plans-ambitious-640mw-renewable-energy-initiative',
     },
  ];
  
  export default BlogsData;
  
import React from 'react';
import { useForm } from 'react-hook-form';
import { TextField, Button, Box, Container, Grid, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import bg from '../assets/call.jpg';

const LetsTalk = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await fetch('https://berc.pk/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(data).toString(),
      });

      if (response.ok) {
        toast.success('Email sent successfully');
      } else {
        toast.error('Email not sent, Please try again');
      }
    } catch (error) {
      toast.error('Error submitting form');
    }
  };

  return (
    <div>
      <ToastContainer />
      <section id="lets-talk" className="h-screen flex mb-0" style={{ backgroundColor: '#DBE1EF' }}>
        <div className="w-1/2 h-full">
          <img src={bg} alt="Background" className="w-full h-full object-cover" />
        </div>
        <div className="w-1/2 h-full flex flex-col items-center justify-center p-10">
          <h1 className="font-medium text-center mb-8" style={{ fontSize: '50px' }}>
            Contact Us!
          </h1>
          <form className="w-full max-w-md bg-white p-8 rounded-lg shadow-md" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register('name', { required: 'Name is required' })}
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
            />
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                  message: 'Invalid email address'
                }
              })}
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ''}
            />
            <TextField
              label="Subject"
              variant="outlined"
              fullWidth
              margin="normal"
              {...register('subject', { required: 'Subject is required' })}
              error={!!errors.subject}
              helperText={errors.subject ? errors.subject.message : ''}
            />
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              margin="normal"
              multiline
              rows={4}
              {...register('message', { required: 'Message is required' })}
              error={!!errors.message}
              helperText={errors.message ? errors.message.message : ''}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Submit
            </Button>
          </form>
        </div>
      </section>

      <section id="lets-talk-2" className="relative h-screen flex items-center justify-center" style={{ backgroundColor: '#fff', marginTop: '-20px' }}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  padding: 4,
                  borderRadius: 2,
                  boxShadow: 3,
                  mb: 4,
                }}
              >
                <Box display="flex" alignItems="center" mb={2}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: '#1055d8', fontSize: '20px', marginRight: '1rem' }} />
                  <Typography variant="h6" component="h2" style={{ fontSize: '20px' }}>
                    Location:
                  </Typography>
                </Box>
                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300 }}>
                  CISNR New Academic Block, Basement, UET, Peshawar, Khyber Pakhtunkhwa 25125
                </Typography>

                <Box display="flex" alignItems="center" mb={2} mt={4}>
                  <FontAwesomeIcon icon={faEnvelope} style={{ color: '#1055d8', fontSize: '20px', marginRight: '1rem' }} />
                  <Typography variant="h6" component="h2" style={{ fontSize: '20px' }}>
                    Email:
                  </Typography>
                </Box>
                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300 }}>
                  info@berc.pk
                </Typography>

                <Box display="flex" alignItems="center" mb={2} mt={4}>
                  <FontAwesomeIcon icon={faPhone} style={{ color: '#1055d8', fontSize: '20px', marginRight: '1rem' }} />
                  <Typography variant="h6" component="h2" style={{ fontSize: '20px' }}>
                    Call:
                  </Typography>
                </Box>
                <Typography variant="body1" style={{ fontSize: '16px', fontWeight: 300 }}>
                  +92-91-9222104
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: '400px',
                  borderRadius: 2,
                  boxShadow: 3,
                  overflow: 'hidden',
                }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.576141560076!2d71.48336697515295!3d34.00169397941384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzTCsDAwJzA2LjEiTiA3McKwMjknMDguMCJF!5e0!3m2!1sen!2s!4v1691049437619!5m2!1sen!2s"
                  width="100%"
                  height="450"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </div>
  );
};

export default LetsTalk;

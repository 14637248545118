import React, { useState, useRef, useEffect } from 'react';
import logo from '../assets/berc-logo.jpeg'; 
import Partner from './Partner';
import ScrollToTopButton from '../components/ScrollToTopButton'; 

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isToolsDropdownOpen, setIsToolsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleToolsDropdown = () => setIsToolsDropdownOpen(!isToolsDropdownOpen);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsToolsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <nav className="fixed top-0 left-0 w-full shadow-md z-20 bg-white">
        <div className="flex items-center justify-between p-3"> 
          <div className="flex items-center space-x-4">
            <img src={logo} alt="Logo" className="h-12" /> 
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-gray-700 focus:outline-none">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
              </svg>
            </button>
          </div>
          <ul className={`flex-col md:flex-row md:flex space-x-0 md:space-x-6 mt-4 md:mt-0 ${isMenuOpen ? 'flex' : 'hidden'} md:flex`}>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a href="#home" className="text-lg font-medium text-gray-700 hover:text-blue-500">Home</a>
            </li>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a href="#why-us" className="text-lg font-medium text-gray-700 hover:text-blue-500">Why Us</a>
            </li>
            <li ref={dropdownRef} className="relative transform hover:scale-110 transition-transform duration-300">
              <button 
                onClick={toggleToolsDropdown} 
                className="flex items-center text-lg font-medium text-gray-700 hover:text-blue-500 focus:outline-none"
              >
                Tools
                <svg
                  className={`w-4 h-4 ml-1 transition-transform duration-200 ${isToolsDropdownOpen ? 'transform rotate-180' : ''}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                </svg>
              </button>
              {isToolsDropdownOpen && (
                <ul className="absolute left-0 mt-2 w-48 bg-white border rounded-md shadow-lg">
                  <li><a href="https://lca.berc.pk/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Life Cycle Assessment (LCA)</a></li>
                  <li><a href="https://rlf.berc.pk/login.php" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Residential Load Factor (RLF)</a></li>
                  <li><a href="https://bpd.berc.pk/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Building Performance Design (BPD)</a></li>
                  <li><a href="https://compliance.berc.pk" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">ECBC Compliance Tool</a></li>
                  <li><a href="https://berc.pk/uvalues/" target="_blank" rel="noopener noreferrer" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">U-Values Finder</a></li>
                </ul>
              )}
            </li>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a href="#services" className="text-lg font-medium text-gray-700 hover:text-blue-500">Services</a>
            </li>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a href="#team" className="text-lg font-medium text-gray-700 hover:text-blue-500">Team</a>
            </li>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a href="#blog" className="text-lg font-medium text-gray-700 hover:text-blue-500">Blog</a>
            </li>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a href="#gallery" className="text-lg font-medium text-gray-700 hover:text-blue-500">Gallery</a>
            </li>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a href="#lets-talk" className="text-lg font-medium text-gray-700 hover:text-blue-500">Let's Talk</a>
            </li>
            <li className="transform hover:scale-110 transition-transform duration-300">
              <a 
                href="#partner" 
                className="text-lg font-medium hover:text-white bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded" 
                onClick={handleOpen}
              >
                Become Our Partner
              </a>
            </li>
          </ul>
        </div>
        <Partner open={open} handleClose={handleClose} />
      </nav>
      <ScrollToTopButton /> 
    </div>
  );
};

export default Navbar;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';

const WhyUs = () => {
  return (
    <section id="why-us" className="min-h-screen flex flex-col items-center justify-center p-8" style={{ backgroundColor: "#E8FFAE" }}>
      <div className="flex flex-col lg:flex-row max-w-6xl w-full">
        <div className="flex flex-col items-center justify-center w-full lg:w-1/3 mb-8 lg:mb-0">
          <h1 className="font-thin text-6xl md:text-7xl lg:text-8xl" style={{ color: "#32CD32" }}>Why Choose</h1>
          <h1 className="font-thin text-6xl md:text-7xl lg:text-8xl" style={{ color: "#32CD32" }}>BERC</h1>
          <FontAwesomeIcon icon={faQuestion} className="mt-4" style={{ fontSize: '100px', color: "#32CD32" }} />
        </div>
        <div className="w-full lg:w-2/3 text-left lg:pl-8">
          <p className="text-lg text-gray-800 mb-8">
            The establishment of the BERC marks a significant milestone in our commitment to advancing research and development in the field of energy, particularly in the area of sustainable building energy solutions. The Centre is Pakistan's first ever state-of-the-art centre working in the field of sustainable buildings design and construction. The centre serves as a hub for interdisciplinary collaboration, bringing together researchers, industry experts, and policymakers to address the energy challenges facing our society.
            The research center has pioneered in promising to provide solutions for Energy Efficiency in Buildings while focusing on five major SDGs including SDG 7: Affordable and Clean Energy, SDG 9: Industry, Innovation and Infrastructure, SDG 11: Sustainable Cities and Communities, SDG 12: Responsible Consumption and Production, and SDG 13: Climate Action. This center has a 28-member team comprising of Mechanical, Electrical, Civil, and Architectural engineers.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105">
              <h2 className="text-2xl font-bold text-gray-800">1.5</h2>
              <p className="text-gray-600">Years Experience</p>
            </div>
            <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105">
              <h2 className="text-2xl font-bold text-gray-800">54</h2>
              <p className="text-gray-600">Trusted Customers</p>
            </div>
            <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105">
              <h2 className="text-2xl font-bold text-gray-800">500+</h2>
              <p className="text-gray-600">Positive Reviews</p>
            </div>
            <div className="bg-white shadow-md p-6 text-center rounded-lg transform transition-transform hover:scale-105">
              <h2 className="text-2xl font-bold text-gray-800">23</h2>
              <p className="text-gray-600">Projects Completed</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;

import React, { useState } from 'react';
import bg from '../assets/blog.jpg'; 
import Modal from '@mui/material/Modal'; 
import { Button, Box, Fade, Typography, Card, CardContent, CardActionArea, Grid } from '@mui/material';
import BlogsData from '../components/blogs/BlogsData';  

const BlogSection = () => {
  const [open, setOpen] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);

  const handleOpen = (blog) => {
    setSelectedBlog(blog);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBlog(null);
  };

  return (
    <div>
      <section id="blog" className="flex flex-col md:flex-row items-center justify-center p-10 h-auto md:h-screen" style={{ backgroundColor: '#fff' }}>
        <div className="w-full md:w-1/2 flex flex-col items-center justify-center p-10">
          <h1 className="text-center mb-4 font-medium text-4xl mt-10 md:mt-0">
            BERC Blog Post!
          </h1>
          <p className="text-center font-thin text-gray-700 mt-5 md:text-2xl">
            Stay updated with the latest trends, insights, and breakthroughs in the world of sustainable technology.
          </p>
        </div>
        <div 
          className="w-full md:w-1/2 h-64 md:h-full"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
      </section>

      <section id="blog" className="w-full p-8 text-center mt-16" style={{ backgroundColor: '#fff' }}>
        <Grid container spacing={4} justifyContent="center">
          {BlogsData.map((blog, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card style={{ backgroundColor: "#e8ffae", height: '300px' }}>
                <CardActionArea onClick={() => handleOpen(blog)} style={{ height: '100%' }}>
                  <CardContent style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Typography variant="h5" component="div">
                      {blog.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {blog.excerpt}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={open}>
            <Box 
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                maxHeight: '80%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                overflow: 'auto',
              }}
            >
              <Typography id="transition-modal-title" variant="h5" component="h2" sx={{ mb: 2, fontSize: '2.5rem' }}>
                {selectedBlog?.title}
              </Typography>
              <Typography id="transition-modal-description" sx={{ whiteSpace: 'pre-line' }}>
                {selectedBlog?.content}
              </Typography>
              <Typography id="transition-modal-description" sx={{ whiteSpace: 'pre-line' }} style={{ color: 'blue' }}>
                {selectedBlog?.excerpt}
              </Typography>
              <Typography id="transition-modal-description" sx={{ whiteSpace: 'pre-line' }}>
                Reference: <span style={{ color: 'blue' }}>{selectedBlog?.reference}</span>
              </Typography>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Button variant="contained" color="primary" onClick={handleClose}>
                  Close
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </section>
    </div>
  );
};

export default BlogSection;

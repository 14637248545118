import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import AnimatedText from '../components/AnimatedText';
import bg from '../assets/b2.jpeg'; 

const Home = () => {
  return (
    <>
      <section className="w-full p-5 text-center mt-16">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Buildings Energy Research Center</h1>
        <h2 className="text-xl font-semibold text-gray-600">Transformation of Buildings in Pakistan: A Pathway Towards Green, Sustainable and Zero Energy Buildings</h2>
      </section>
      <section id="home" className="relative flex flex-col lg:flex-row h-screen">
        <div className="w-full lg:w-2/5 h-1/2 lg:h-full flex items-center justify-center">
          <AnimatedText />
        </div>
        <div className="w-full lg:w-3/5 h-1/2 lg:h-full">
          <img
            src={bg}
            alt="Background"
            className="w-full h-full object-cover object-center"
            loading="lazy"
          />
        </div>
      </section>
      <section id="video" className="w-full p-8 text-center flex flex-col items-center gap-10">
        <p className="mb-4 text-black font-semibold text-lg">Our recent seminar highlights, "Framework for the Implementation of Sustainability and Energy Efficiency in Buildings: Adoption Strategy for Public and Private Sectors," held on 29th February at Serena Hotel in Peshawar.</p>
        <div className="w-full max-w-4xl">
          <iframe 
            width="100%"
            height="500" 
            src="https://www.youtube.com/embed/lLF1-LTXn_s" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
          ></iframe>
        </div>
      </section>
    </>
  );
};

export default Home;

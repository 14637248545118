import React, { useState } from 'react';
import { Button, Box, Modal, Fade, Backdrop, Typography, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import bg from '../assets/tool1.jpg';
import bpdImg1 from '../assets/toolsImages/bpd-img1.jpg';
import bpdImg2 from '../assets/toolsImages/bpd-img2.jpg';
import bpdImg3 from '../assets/toolsImages/bpd-img3.jpg';
import bpdImg4 from '../assets/toolsImages/bpd-img4.jpg';
import lcaImg1 from '../assets/toolsImages/lca-img1.jpg';
import lcaImg2 from '../assets/toolsImages/lca-img2.jpg';
import lcaImg3 from '../assets/toolsImages/lca-img3.jpg';
import lcaImg4 from '../assets/toolsImages/lca-img4.jpg';
import lcaImg5 from '../assets/toolsImages/lca-img5.jpg';
import lcaImg6 from '../assets/toolsImages/lca-img6.jpg';
import lcaImg7 from '../assets/toolsImages/lca-img7.jpg';
import rlfImg1 from '../assets/toolsImages/rlf-img1.jpg';
import rlfImg2 from '../assets/toolsImages/rlf-img2.jpg';
import rlfImg3 from '../assets/toolsImages/rlf-img3.jpg';
import rlfImg4 from '../assets/toolsImages/rlf-img4.jpg';
import rlfImg5 from '../assets/toolsImages/rlf-img5.jpg';
import comImg1 from '../assets/toolsImages/com-img1.png';
import comImg2 from '../assets/toolsImages/com-img2.png';
import comImg3 from '../assets/toolsImages/com-img3.png';
import comImg4 from '../assets/toolsImages/com-img4.png';
import comImg5 from '../assets/toolsImages/com-img5.png';
import comImg6 from '../assets/toolsImages/com-img6.png';
import comImg7 from '../assets/toolsImages/com-img7.png';
import comImg8 from '../assets/toolsImages/com-img8.png';
import comImg9 from '../assets/toolsImages/com-img9.png';
import comImg10 from '../assets/toolsImages/com-img10.png';

import UImg from '../assets/toolsImages/uvalue-img.jpg'; 

const tools = [
  { name: 'LCA (Life Cycle Assessment) Tool', description: 'This tool calculates the carbon emissions along with the estimated cost of the construction project based on the specific details of the construction project entered by the user. ' },
  { name: 'BPD (Building Performance Database) Tool', description: 'The database tool calculates the energy consumption of the buildings and stores the user-entered data for future reference. Whenever a new user enters details for their project, the database compares it with buildings of similar size and location, providing insights on how the new building will perform in comparison, along with tailored strategies to incorporate energy-efficiency in the project. ' },
  { name: 'RLF (Residential Load Factor) Tool', description: 'This unique tool requires users to input specific data related to the building such as location of the building, dimensions and composition of roof and walls, and electrical appliances. The tool calculates the energy consumption of the building along with its carbon footprint. The tool then issues green certification based on the results akin to international rating systems such as LEED or EDGE.' },
  { name: 'ECBC Compliance Tool', description: 'This online tool requires users to input detailed information about their construction project, including the composition and dimensions of the walls, roof and windows, as well as the number and wattage of lighting and other electrical equipment. It compares the data with the requirements of ECBC – 2023 and generates a compliance report along with recommendations and suggestions for improvement, similar to the COMcheck tool, used in USA for the same purpose. ' },
  { name: 'U-Values Finder for Pakistan Cities', description: 'BERC introduces a set of tools, developed with dedication that helps empowering civil engineers and construction professionals with cutting-edge tools designed to enhance efficiency, precision, and compliance in their projects. Our suite of innovative tools is developed with the latest technology to meet the diverse needs of the industry.' },
];

const Tools = () => {
  const [open, setOpen] = useState(false);
  const [selectedTool, setSelectedTool] = useState(null);

  const handleOpen = (tool) => {
    setSelectedTool(tool);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTool(null);
  };

  return (
    <div>
      <section id="tools" className="flex flex-col md:flex-row h-auto md:h-screen mb-0" style={{ backgroundColor: '#ffff' }}>
        <div
          className="w-full md:w-1/2 h-64 md:h-full"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        >
        </div>
        <div className="w-full md:w-1/2 h-auto flex flex-col items-center justify-start p-10">
          <h1
            className="text-center font-medium mb-8 text-2xl md:text-4xl"
          >
            Our Tools!
          </h1>
          <p
            className="text-center font-thin text-sm md:text-lg mt-2 md:mt-8"
          >
            BERC introduces a set of tools, developed with dedication that helps empowering civil engineers and construction professionals with cutting-edge tools designed to enhance efficiency, precision, and compliance in their projects. Our suite of innovative tools is developed with the latest technology to meet the diverse needs of the industry.
          </p>

          <div className="mt-8 flex flex-wrap justify-center gap-2">
            {tools.map((tool) => (
              <Tooltip title="Click to learn more" arrow key={tool.name} >
                <Button
                  variant="contained"
                  onClick={() => handleOpen(tool)}
                  style={{
                    background: 'linear-gradient(45deg, #A9ff00 30%, #A9ff00 90%)',
                    border: 0,
                    borderRadius: 3,
                    boxShadow: '0 3px 5px 2px rgba(232, 255, 174)',
                    color: 'black',
                    height: 48,
                    padding: '0 30px',
                    margin: '10px',
                    transition: 'transform 0.2s ease-in-out',
                  }}
                  className="hover:transform hover:scale-105"
                >
                  {tool.name}
                </Button>
              </Tooltip>
            ))}
          </div>

          <p
            className="text-center font-thin text-sm md:text-lg mt-2 md:mt-8"
          >
            <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: '10px', color: '#bebebe' }} />
            Each tool comes with a comprehensive video tutorial for easy understanding and efficient use
          </p>
        </div>
      </section>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              height: '80%',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              overflow: 'auto',
            }}
          >
            <Typography
              id="transition-modal-title"
              variant="h5"
              component="h2"
              sx={{ textAlign: 'center', mb: 2, fontSize: '2xl' }}
            >
              {selectedTool?.name}
            </Typography>
          
            {selectedTool?.name === 'BPD (Building Performance Database) Tool' ? (
              <>
                <a href=" https://bpd.berc.pk/" target="_blank" rel="noopener noreferrer" style={{ display: 'block', textAlign: 'center', fontSize: '20px', textDecoration: 'none', color: '#3B82F6', marginBottom: '10px' }}>
                  https://bpd.berc.pk/
                </a>
                <Carousel showThumbs={false}>
                  <div>
                    <img src={bpdImg1} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={bpdImg2} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={bpdImg3} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={bpdImg4}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                </Carousel>
                <Typography id="transition-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                  {selectedTool?.description}
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
              </>
            ) : selectedTool?.name === 'LCA (Life Cycle Assessment) Tool' ? (
              <>
                <a href="https://lca.berc.pk/" target="_blank" rel="noopener noreferrer" style={{ display: 'block', textAlign: 'center', fontSize: '20px', textDecoration: 'none', color: '#3B82F6', marginBottom: '10px' }}>
                  https://lca.berc.pk/
                </a>
                <Carousel showThumbs={false}>
                  <div>
                    <img src={lcaImg1} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={lcaImg2}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={lcaImg3} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={lcaImg4}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={lcaImg5} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={lcaImg6} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={lcaImg7}style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                </Carousel>
                <Typography id="transition-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                  {selectedTool?.description}
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
              </>
            ) : selectedTool?.name === 'RLF (Residential Load Factor) Tool' ? (
              <>
                <a href=" https://rlf.berc.pk/login.php" target="_blank" rel="noopener noreferrer" style={{ display: 'block', textAlign: 'center', fontSize: '20px', textDecoration: 'none', color: '#3B82F6', marginBottom: '10px' }}>
                  https://rlf.berc.pk/login.php
                </a>
                <Carousel showThumbs={false}>
                  <div>
                    <img src={rlfImg1}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={rlfImg2}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={rlfImg3}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={rlfImg4}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={rlfImg5}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                </Carousel>
                <Typography id="transition-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                  {selectedTool?.description}
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
              </>
            ) : selectedTool?.name === 'ECBC Compliance Tool' ? (
              <>
                <a href="https://compliance.berc.pk" target="_blank" rel="noopener noreferrer" style={{ display: 'block', textAlign: 'center', fontSize: '20px', textDecoration: 'none', color: '#3B82F6', marginBottom: '10px' }}>
                https://compliance.berc.pk
                </a>
                <Carousel showThumbs={false}>
                  <div>
                    <img src={comImg1}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg2} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg3} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg4} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg5}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg6} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg7}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg8} style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg9}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                  <div>
                    <img src={comImg10}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                
                </Carousel>
                <Typography id="transition-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                  {selectedTool?.description}
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
              </>
            ) : selectedTool?.name === 'U-Values Finder for Pakistan Cities' ? (
              <>
                <a href="https://berc.pk/uvalues/" target="_blank" rel="noopener noreferrer" style={{ display: 'block', textAlign: 'center', fontSize: '20px', textDecoration: 'none', color: '#3B82F6', marginBottom: '10px' }}>
                  https://berc.pk/uvalues/
                </a>
                <Carousel showThumbs={false}>
                  <div>
                    <img src={UImg}  style={{ maxHeight: '500px', objectFit: 'contain' }} />
                  </div>
                </Carousel>
                <Typography id="transition-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                  {selectedTool?.description}
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button variant="contained" color="primary" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Typography id="transition-modal-description" sx={{ mt: 2, textAlign: 'center' }}>
                  {selectedTool?.description}
                </Typography>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <Button variant="contained" color="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Tools;
